/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, {
  useState,
  useCallback,
  forwardRef,
  useEffect,
  useRef,
} from "react";
import { GoogleMapsProvider } from "@ubilabs/google-maps-react-hooks";
import { useGoogleMap } from "@ubilabs/google-maps-react-hooks";
import { HubConnectionBuilder, LogLevel } from "@microsoft/signalr";

const markers = [
  {
    lat: 19.848855,
    lng: -98.976443,
  },
];

const mapOptions = {
  zoom: 13,
  center: { lat: 20.0703333, lng: -98.7301964 },
};

const dat = {
  longitude: "-98.73019643721376",
  latitude: "20.070333304756137",
  distance: "50000",
    TripGUID: "idtemporal",
    "driverId": "",
    "description": "","clientId":""
};

const MapMarkers = () => {
  const wsConnect = async () => {
    const connection = new HubConnectionBuilder()
        .withUrl("https://dev2.tuzotaxi.com/signalr")
      .withAutomaticReconnect()
      .configureLogging(LogLevel.Information)
      .build();
    console.log("Conectando");

    connection.on("SendMessageResponse", (user, messange, test) => {
      console.log(user, messange, test);
    });

    connection.on(
      "DriverLocationResponse",
      (driverGUID, latitude, longitude) => {
        let fecha = new Date();
        console.log(
          "Location",
          fecha.getHours() +
            ":" +
            fecha.getMinutes() +
            ":" +
            fecha.getSeconds(),
          driverGUID,
          latitude,
          longitude
        );
        //console.log(markersList.current[driverGUID]);
        if (markersList.current[driverGUID])
          markersList.current[driverGUID].setPosition({
            lat: parseFloat(latitude),
            lng: parseFloat(longitude),
          });
      }
    );

    connection.onclose(() => {
      console.warn("Connection closed");
    });
    await connection.start();
    if (connection._connectionState === "Connected") {
      console.log("Socket Connection established");
    }
  };

  const [inited, setInited] = useState(false);

  // Get the global map instance with the useGoogleMap hook
  const map = useGoogleMap();
  let marker1 = null;
  const [markers, setMarkers] = useState([]);
  // const [thisMarker, setThisMarker] = useState(null);
  const markersList = useRef();
  const getData = async () => {
    let data = await fetch("/api/Drivers/GetNearDrivers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        //Authorization: "Bearer " + token,
      },
      body: JSON.stringify(dat),
    });
    let text = await data.text();
    let json = await JSON.parse(text);

    const initialBounds = new google.maps.LatLngBounds();
    //console.log("MAPA", json);

    if (!markersList.current) markersList.current = {};
    json.data.map((it, x) => {
      let position = {
        lat: parseFloat(it.geos.datoAdicional01),
        lng: parseFloat(it.geos.datoAdicional02),
      };
      console.log(it.geos);
      const markerData = {
        map,
        position,
        label: "FUT-0121" + it.geos.descripcion,
        title: "test22",
        //title: it.geos.id.toString(),
      };
      if (!markersList.current[it.geos.guid]) {
        markersList.current[it.geos.guid] = new google.maps.Marker(markerData);
      } else {
        markersList.current[it.geos.guid].setPosition(position);
      }
    });

    let datos = [];

    Object.keys(markersList.current).forEach((key) => {
      datos.push(markersList.current[key].getPosition());
    });
    console.log(datos);
    let line = new google.maps.Polyline({
      map,
      geodesic: true,
      strokeColor: "#333333",
      strokeOpacity: 1.0,
      strokeWeight: 4,
      path: datos,
    });
    setInited(true);
  };

  // Add markers to the map
  useEffect(() => {
    console.log("Startup");
    if (!map) {
      return () => {};
    }
    console.log("Startup2");
    wsConnect();
    getData();

    //const initialBounds = new google.maps.LatLngBounds();
  }, [map]);
};

const addMarker = (map) => {
  console.log(map);
  const markers1 = markers.map(({ lat, lng }) => {
    const markerData = {
      map,
      position: { lat: parseFloat(lat), lng: parseFloat(lng) },
    };
    const marker = new google.maps.Marker(markerData);
    return marker;
  });

  // return null;
};

function FullScreenMap() {
  const [mapContainer, setMapContainer] = useState(null);
  const [markData, setMarkData] = useState([]);
  const mapRef = useCallback((node) => {
    node && setMapContainer(node);
  }, []);
  const onLoad = useCallback((map) => addMarker(map), []);

  useEffect((mapRef) => {
    const markerOptions = {
      mapRef,
      position: markers[0],
      title: "nombre",
      clickable: false,
    };

    // initialBounds.extend(position);

    //new google.maps.Marker(markerOptions);
  }, []);

  return (
    // <div id="map" className="map-content">
    //   <MapWrapper />
    // </div>
    <GoogleMapsProvider
      googleMapsAPIKey="AIzaSyBjqCrRopdTjPYxidm0vJajsr72rYJz4Qo"
      mapOptions={mapOptions}
      mapContainer={mapContainer}
      //onLoadMap={onLoad}
    >
      <React.StrictMode>
        <div ref={mapRef} style={{ height: 900 }}></div>
        <MapMarkers />
      </React.StrictMode>
    </GoogleMapsProvider>
  );
}

export default FullScreenMap;
